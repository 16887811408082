export const kPublicWelfarePageContent = {
  pageName: "公益贡献",
  title: "公益贡献",
  content: `归农积极落实国家“乡村振兴”战略，首创农产品社群社交电商模式，以独有的“社群+社团+社区”方式，通过“社群社团化、
    社团本地化”进行内容营销、销售布局，在四川金川、河南开封、江西泰和、新疆拜城、云南元江、甘肃民勤等多个地区助推当地农产品销售，屡创惊人佳绩，为农业创新发展创造了巨大的经济价值与社会效益。`,
  children: [
    { 
      "title": "归农受海南文昌发改委邀请，助力乡村振兴，参加文昌妃子笑荔枝直播活动，归农当日销售荔枝3万份，合计15万斤。", 
      "date": "2021-05", 
      "conver": require('@/assets/publicWelfare/gygx_09.jpg'), 
    },
    { 
      "title": "归农为上饶当地特产葛根带货，当天销售20万袋。", 
      "date": "2020", 
      "conver": require('@/assets/publicWelfare/gygx_08.jpg'), 
    },
    { 
      "title": "江西泰和县委调研员在归农“直播间”带货泰和乌鸡系列产品，当天直播销售了乌鸡蛋18万枚、乌鸡酒 1.2万瓶、乌鸡酱油2万瓶。", 
      "date": "2020-12", 
      "conver": require('@/assets/publicWelfare/gygx_06.jpg'), 
    },
    { 
      "title": "开封市祥符区区长在归农“直播间”货卖开封沙地蜜薯，当天销售6万多单，合计30多万斤，是当天所有参与这次活动中销量最大、直播间观众最多的电商平台。", 
      "date": "2020-07", 
      "conver": require('@/assets/publicWelfare/gygx_05.jpg'), 
    },
    { 
      "title": "疫情期间，上架两次开封大蒜，销售28万单，合计100多万斤。", 
      "date": "2020-05", 
      "conver": require('@/assets/publicWelfare/gygx_04.jpg'), 
    },
    { 
      "title": "金川雪梨膏，创1分钟销售5500单的奇迹。截至2020.12.31，瓶装雪梨膏127万单，袋装雪梨膏18.8万单，累积帮助果农消化鲜梨约2900万斤。", 
      "date": "2017-12", 
      "conver": require('@/assets/publicWelfare/gygx_03.jpg'), 
    },
    { 
      "title": "江西泰和乌鸡蛋，单日销售3万多枚", 
      "date": "2015-10", 
      "conver": require('@/assets/publicWelfare/gygx_01.jpg'), 
    },
  ]
}