
import { Options, Vue } from "vue-class-component";
import { kPublicWelfarePageContent } from "@/config/Common/public_welfare_data";
import BoxWithPublicNews from "@/components/PC/BoxWithPublicNews.vue";

@Options({
  components: {
    BoxWithPublicNews,
  },
  data() {
    return {
      info: kPublicWelfarePageContent,
    };
  },
})
export default class PublicWelfare extends Vue {}
